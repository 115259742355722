import extend from 'lodash/extend';
import bind from 'lodash/bind';
import Logger from 'utils/logger';
import CoreTopBarComponent from 'components/core_top_bar';
import CloudstoreTopBarComponent from 'components/cloudstore_top_bar';
import LazyLoaderComponent from 'components/lazy_loader';
import AuthFlowComponent from 'components/auth_flow';
import ButtonComponent from 'components/button';
import LanguageSelector from 'components/language_selector';
import CookieNotificationComponent from 'components/cookie_notification';

class ApplicationController {
  constructor(options = {}) {
    extend(this, options);

    this.components = {
      coreTopBar: new CoreTopBarComponent(),
      cloudstoreTopBar: new CloudstoreTopBarComponent(),
      lazyLoader: new LazyLoaderComponent(),
      authFlow: new AuthFlowComponent(),
      submitButton: new ButtonComponent(),
      languageSelector: new LanguageSelector(),
      cookieNotification: new CookieNotificationComponent(),
    };
  }

  _isCore() {
    return this.storeContext === 'core';
  }

  _isCloudstore() {
    return this.storeContext === 'cloudstore';
  }

  _storeContext() {
    return this.storeContext;
  }

  init() {
    let {
      coreTopBar,
      cloudstoreTopBar,
      lazyLoader,
      authFlow,
      submitButton,
      languageSelector,
      cookieNotification
    } = this.components;

    if (this._isCore()) {
      coreTopBar.init();
      cookieNotification.init();
    }

    if (this._isCloudstore()) {
      cloudstoreTopBar.init();
      authFlow.init();
      languageSelector.init();
    }

    lazyLoader.init();
    submitButton.init({
      selector: '.auth__submit',
      actions: {
        onClick: bind(submitButton.setLoadingState, submitButton)
      }
    });

    Logger.log('DEBUG:', this);
  }
}

export default ApplicationController;
